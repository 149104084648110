<template>
  <div class="indexContent">
    <!-- pc端轮播 -->
    <div class="px-md-5 carousel carouselImage">
      <img class="carouselImg" src="../../assets/2.png" alt="" />
      <div class="carouselDiv">
        <el-carousel :interval="4000" type="card">
          <el-carousel-item v-for="item in 3" :key="item">
            <img class="carouselLiImg" src="../../assets/3.jpg" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

    <div class="container customize-width px-md-5 carousel carouselImageWap">
      <el-carousel trigger="click" height="200px">
        <el-carousel-item v-for="item in 4" :key="item">
          <img class="carouselLiImg" src="../../assets/3.jpg" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="header-big no-bg mt-0 mb-0 mb-md-4 pb-0 pt-2 pt-md-5">
      <div class="s-search m-md-0 px-md-5">
        <div id="search" class="s-search mx-auto">
          <div id="search-list-menu" class="mb-2">
            <div class="s-type text-center">
              <div class="s-type-list big tab-auto-scrollbar overflow-x-auto">
                <div class="anchor" style="position: absolute; left: 50%; opacity: 0;"></div>
                <a href="https://jutusou.huamao.vip/" class="ad" target="_blank">聚图搜</a>
                <div class="tapTabs">
                  <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane v-for="item of tabsList" :key="item.key" :label="item.title" :name="item.key"></el-tab-pane>
                  </el-tabs>
                </div>
              </div>
            </div>
          </div>
          <div class="searchDiv" :class="searAction.key">
            <el-select
              v-model="searchValue"
              filterable
              remote
              reserve-keyword
              placeholder="请输入关键字搜索"
              :remote-method="remoteMethod"
              :loading="loading"
            >
              <el-option v-for="item in optionsList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
            <div class="searchSlot" @click="searchClick">
              <i class="el-icon-search"></i>
            </div>
          </div>
          <div class="popular-search">
            <span><i class="io io-huore icon-fw mr-1"></i>热搜：</span>
            <a href="#" @click="goReSou">动漫</a>
            <a href="#" @click="goReSou">影视</a>
            <a href="#" @click="goReSou">漫画</a>
            <a href="#" @click="goReSou">壁纸</a>
            <a href="#" @click="goReSou">AI</a>
            <a href="#" @click="goReSou">小说</a>
            <a href="#" @click="goReSou">音乐</a>
            <a href="#" @click="goReSou">短剧</a>
            <a href="#" @click="goReSou">搜索</a>
            <a href="#" @click="goReSou">导航</a>
          </div>
        </div>
      </div>
    </div>

    <div class="container customize-width px-md-5">
      <div class="content">
        <div class="content-wrap">
          <div class="content-layout ">
            <!-- 白金赞助 -->
            <div class="block-header my-3 my-md-4">
              <h4 class="block-title"><i class="io io-dengji"></i>白金赞助</h4>
              <div class="flex-fill"></div>
              <a :href="hm.link.contact"><i class="io io-xiaoxi icon-fw mr-1"></i>联系站长<i class="iconfont icon-arrow-r-m"></i></a>
            </div>
            <div class="card-grid card-grid2 ">
              <div v-for="index of 4" class="url-card">
                <div class="sponsors-body url-body">
                  <img class="urlImg" src="@/assets/1.jpg" alt="" />
                </div>
              </div>
            </div>
            <!-- 新版ggggggggg -->
            <div class="section-box" v-for="item in silidebarList" :key="item.id" :id="item.ref">
              <div class="block-header my-3 my-md-4">
                <h4 class="block-title"><i :class="item.icon"></i>{{ item.name }}</h4>
                <div class="flex-fill"></div>
                <a href="#" @click="goGengDuo">更多<i class="iconfont icon-arrow-r-m"></i></a>
              </div>
              <div class="card-grid">
                <div class="url-card" v-for="(items, child) in item.children" :key="child">
                  <div class="url-body">
                    <a href="#" @click="goDetails" class="url-content">
                      <div class="url-img">
                        <img class="lazy" :src="items.img" :alt="items.name" />
                      </div>
                      <div class="url-info">
                        <div class="overflowClip_1 mb-1 mb-md-2">
                          <strong>{{ items.name }}</strong
                          ><span class="badge" v-if="items.tagName">{{ items.tagName }}</span>
                        </div>
                        <p class="overflowClip_2">
                          {{ items.desc }}
                        </p>
                      </div>
                    </a>
                    <div class="url-link" v-if="items.tags && items.tags.length">
                      <div class="swiper-wrapper">
                        <a v-for="(tag, tagKey) in items.tags" :key="tagKey" :href="tag.href" class="swiper-slide" target="_blank" rel="nofollow">{{
                          tag.name
                        }}</a>
                      </div>
                    </div>
                    <a v-if="items.isShowMoreTag" href="index1.html" class="url-details" target="_blank" rel="nofollow"
                      ><i class="iconfont icon-goto"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
            <!-- 新版ggggggggg -->

            <!--最新资讯  -->
            <div class="block-header my-3 my-md-4">
              <h4 class="block-title"><i class="io io-renwu"></i>最新资讯</h4>
              <div class="flex-fill"></div>
              <a href="#" @click="goGengDuo">更多<i class="iconfont icon-arrow-r-m"></i></a>
            </div>
            <div class="card-grid">
              <div class="post-card" v-for="item in 3">
                <div class="post-body" @click="goGengDuoZxun">
                  <div class="post-content">
                    <a class="overflowClip_2"><strong>网站文章是否需要插入图片？对SEO排名的影响是什么？</strong></a>
                    <div class="post-footer">
                      <div class="post-time">7分钟前</div>
                      <div class="flex-fill"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 友情链接 -->
            <div class="block-header my-3 my-md-4">
              <h4 class="block-title"><i id="friendlink" class="iconfont icon-url"></i>友情链接</h4>
              <div class="flex-fill"></div>
              <a :href="hm.link.contact"><i class="io io-xiaoxi icon-fw mr-1"></i>向站长申请<i class="iconfont icon-arrow-r-m"></i></a>
            </div>
            <div class="friendlink card mb-3 mb-md-4">
              <div class="card-body">
                <a href="" target="_blank" v-for="item in 10">{{ siteName }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹框 -->
    <el-drawer direction="ltr" :visible.sync="drawerDialog" :before-close="handleClose" custom-class="drawerSlideBar">
      <slideBar :isActive="isActive" :silidebarList="silidebarList" @scrollToAnchor="scrollToAnchor" />
    </el-drawer>
  </div>
</template>
<script>
import Class from '../class/class'
import slideBar from '../Index/components/slideBar.vue'
export default {
  components: {
    Class,
    slideBar
  },
  data() {
    return {
      hm: {
        theme: {
          mainColor: '#2254f4',
          mainColorActive: '#1f4bdb',
          mainColor10: 'rgba(34, 84, 244, .1)',
          mainColor20: 'rgba(34, 84, 244, .2)',
          mainColor30: 'rgba(34, 84, 244, .3)',
          mainColor50: 'rgba(34, 84, 244, .5)',
          mainColor60: 'rgba(34, 84, 244, .6)'
        },
        curDomain: 'https://huamao.vip/',
        userInfo: {},
        link: {
          passApiUrl:
            'https://api.colorpuss.com/user/auth/pass/?callback_url=https%3A%2F%2Fhuamao.vip%2F&auth_callback_url=https%3A%2F%2Fhuamao.vip%2Fapi%2Fuser%2FsaveCertificate%2F&auth_page_url=https%3A%2F%2Fwww.colorpuss.com%2Flogin%2F',
          contact: '/about/#l-contact',
          qqCs: 'http://wpa.qq.com/msgrd?v=3&amp;uin=1147979142&amp;site=qq&amp;menu=yes',
          search: '/s/',
          searchAssociate: 'https://huamao.vip/api/associate/search/'
        }
      },
      isMobile: false,
      drawerDialog: false,
      silidebarList: [
        {
          id: 1,
          name: '花猫联盟',
          ref: 'section1',
          icon: 'io io-VIP',
          isShowMore: true,
          children: [
            {
              img: require('../../assets/imgs/avatar.jpg'),
              name: '花猫壁纸',
              tagName: '较多收藏',
              desc: '国内最良心的ACG类图片网站，所有资源免费下载；网站内容涵盖了游戏原画、日系插画、二次元插画、手绘、动漫、COS等作品。',
              tags: [
                {
                  name: '最新图片',
                  href: '/golink/1'
                },
                {
                  name: '热门图片',
                  href: '/golink/2'
                },
                {
                  name: '找画师',
                  href: '/golink/3'
                },
                {
                  name: '人物墙',
                  href: '/golink/7'
                },
                {
                  name: '标签',
                  href: '/golink/9'
                },
                {
                  name: '关闭安全过滤',
                  href: '/golink/10'
                },
                {
                  name: '电脑壁纸',
                  href: '/golink/170'
                },
                {
                  name: '手机壁纸',
                  href: '/golink/171'
                },
                {
                  name: '头像',
                  href: '/golink/172'
                },
                {
                  name: '喵小吉',
                  href: 'search/html'
                }
              ],
              isShowMoreTag: true
            }
          ]
        },
        {
          id: 2,
          name: '搜索引擎',
          ref: 'section2',
          icon: 'io io-sousuo',
          isShowMore: true,
          children: [
            {
              img: require('../../assets/imgs/avatar.jpg'),
              name: '秘塔AI搜索',
              desc: '一款能够深入理解问题的AI搜索引擎，没有广告，直达结果。',
              isShowMoreTag: true
            }
          ]
        },
        {
          id: 3,
          name: '休闲娱乐',
          ref: 'section3',
          icon: 'io io-women',
          isShowMore: true,
          children: [
            {
              img: require('../../assets/imgs/avatar.jpg'),
              name: '呵呵动漫',
              tagName: '完全免费',
              desc: '免费高质量在线动漫网站！为动漫爱好者提供无与伦比的追番体验！',
              isShowMoreTag: true
            }
          ]
        },
        {
          id: 4,
          name: '工作办公',
          ref: 'section4',
          icon: 'io io-gonggao',
          isShowMore: true,
          children: [
            {
              img: require('../../assets/imgs/avatar.jpg'),
              name: 'DeepL翻译',
              desc: '做外贸工作必备翻译网站，比百度翻译还好用的在线翻译网站，唯一缺点就是国内访问很慢。',
              isShowMoreTag: true
            }
          ]
        },
        {
          id: 5,
          name: '网络科技',
          ref: 'section5',
          icon: 'io io-faxian',
          isShowMore: true,
          children: [
            {
              img: require('../../assets/imgs/avatar.jpg'),
              name: '米维网络',
              tagName: '4年老站',
              desc: '企业建站及品牌推广一站式服务的服务商。主要业务范围包含网站建设、全网推广、抖音搜索运营、SEO按天扣费、竞价运营、整站优化等。',
              isShowMoreTag: true,
              tags: [
                {
                  name: '网站建设',
                  href: '/golink/298'
                },
                {
                  name: '全网推广',
                  href: '/golink/298'
                },
                {
                  name: '抖音搜索运营',
                  href: '/golink/298'
                },
                {
                  name: 'SEO按天扣费',
                  href: '/golink/298'
                },
                {
                  name: '竞价运营',
                  href: '/golink/298'
                },
                {
                  name: '整站优化',
                  href: '/golink/298'
                }
              ]
            }
          ]
        },
        {
          id: 6,
          name: '学习教育',
          ref: 'section6',
          icon: 'io io-renwu',
          isShowMore: true,
          children: [
            {
              img: require('../../assets/imgs/avatar.jpg'),
              name: '学习教育',
              desc:
                '提供了编程的基础技术教程, 介绍了HTML、CSS、Javascript、Python，Java，Ruby，C，PHP , MySQL等各种编程语言的基础知识。同时本站中也提供了大量的在线实例，通过实例，您可以更好的学习编程。',
              isShowMoreTag: true
            }
          ]
        },
        {
          id: 7,
          name: 'AI工具',
          ref: 'section7',
          icon: 'io io-yinle',
          isShowMore: true,
          children: [
            {
              img: require('../../assets/imgs/avatar.jpg'),
              name: '秘塔AI搜索',
              desc: '一款能够深入理解问题的AI搜索引擎，没有广告，直达结果。',
              isShowMoreTag: true
            }
          ]
        },
        {
          id: 8,
          name: '自媒体平台',
          ref: 'section8',
          icon: 'io io-zhibo',
          isShowMore: true,
          children: [
            {
              img: require('../../assets/imgs/avatar.jpg'),
              name: '快手创作者服务平台',
              desc: '为创作者/机构提供强大的运营管理、数据分析、内容生产等辅助工具、依托平台丰富的资源提供热点趋势，更好的服务每个创作者。',
              isShowMoreTag: true
            }
          ]
        }
      ],
      isActive: false,
      activeName: '',
      tabsList: [
        {
          title: '站内',
          href: '',
          color: '#2254f4',
          key: 'zn'
        },
        {
          title: '必应',
          href: 'https://www.bing.com/search?q=',
          color: '#2254f4',
          key: 'by'
        },
        {
          title: 'Yandex',
          href: 'https://yandex.com/search/?text=',
          color: 'rgb(255, 204, 0)',
          key: 'Yandex'
        },
        {
          title: '谷歌',
          href: 'https://www.google.com/search?q=',
          color: '#2254f4',
          key: 'gg'
        },
        {
          title: '百度',
          href: 'https://www.baidu.com/s?wd=',
          color: 'rgb(78, 110, 242)',
          key: 'bd'
        },
        {
          title: '360',
          href: 'https://www.so.com/s?q=',
          color: 'rgb(15, 178, 100)',
          key: 'L360'
        },
        {
          title: '搜狗',
          href: 'rgb(255, 90, 52)',
          color: 'https://www.sogou.com/web?query=',
          key: 'ss'
        },
        {
          title: '头条',
          href: 'https://so.toutiao.com/search?keyword=',
          color: 'rgb(240, 65, 66)',
          key: 'tt'
        }
      ],
      optionsList: [],
      loading: false,
      searchValue: '',
      searAction: {},
      siteName: localStorage.getItem('siteName')
    }
  },

  watch: {
    isMobile(val) {
      this.drawerDialog = false
    }
  },
  created() {
    this.checkMobile()
    window.addEventListener('resize', this.checkMobile)
  },
  destroyed() {
    window.removeEventListener('resize', this.checkMobile)
  },
  methods: {
    // 判断是否是手机端
    checkMobile() {
      this.isMobile = window.innerWidth <= 768
    },
    // 锚点导航
    scrollToAnchor(refName) {
      this.$nextTick(() => {
        const element = document.querySelector('#' + refName)
        if (element) {
          // 平滑滚动到元素
          window.scrollTo({
            behavior: 'smooth',
            top: element.offsetTop - 80
          })
        }
        if (this.isMobile) {
          this.drawerDialog = false
        }
      })
    },
    drawerShow() {
      this.drawerDialog = true
      this.isActive = true
    },
    handleClose() {
      this.drawerDialog = false
      this.isActive = false
    },
    goClass() {
      console.log('123')
      this.$router.push('/class')
    },
    goNew() {
      this.$router.push('/new')
    },
    goRanking() {
      this.$router.push('/ranking')
    },
    goDetails() {
      this.$router.push('/details')
    },
    goGengDuo() {
      this.$router.push('/gengduo')
    },
    goGengDuoZxun() {
      this.$router.push('/zixun')
    },
    goReSou() {
      this.$router.push('/resou')
    },
    toggleMenu() {
      this.isActive = !this.isActive
    },
    remoteMethod(query) {
      // if (query !== '') {
      //   this.loading = true

      // } else {
      //   this.optionsList = []
      // }
      this.optionsList = []
      this.optionsList.push({
        label: query,
        value: query
      })
    },
    // 点击切换
    handleClick() {
      this.searAction = this.tabsList.filter((res) => res.key == this.activeName)[0]
      console.log(this.activeName, JSON.stringify(this.searAction))
      // searAction
    },
    // 点击搜索
    searchClick() {
      window.open(this.searAction.href + this.searchValue)
    }
  }
}
</script>
<style scoped>
@import url('../css/bootstrap.css');
@import url('../css/style.css');

.menu-icon {
  display: inline-block;
  cursor: pointer;
  width: 25px;
  height: 20px;
  position: relative;
}

.barDiv {
  background-color: black;
  position: absolute;
  left: 0;
  transition: width 0.3s ease-in-out;
  height: 3px;
  width: 100%;
  border-radius: 1.5px;
}

.top {
  top: 0;
}

.middle {
  top: 50%;
  transform: translateY(-50%);
  transition: none;
  /* No transition for the middle bar */
}

.bottom {
  bottom: -1px;
}

.barDiv.active {
  width: 60%;
  /* Change width to 50% when active */
}

/* 轮播部分 */
.carouselImg {
  width: 100%;
}

.carousel {
  position: relative;
}

.carouselDiv {
  width: 1100px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.s-type-list {
  display: flex !important;
}

/* 白金赞助 */

.urlImg {
  width: 100%;
  border-radius: 0.625rem;
  background-color: #f1f4f9;
  transition: background-color 0.3s;
  will-change: transform;
  overflow: hidden;
  border: 2px solid var(--main-color-50);
  box-shadow: 0 5px 20px -3px var(--main-color-20);
  cursor: pointer;
}

.card-grid2 {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

.card-grid2 .url-card:hover .urlImg {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}
</style>
<style scoped lang="less">
.mini-sidebar .slide-bar-ul .slide-bar span {
  display: none;
}

/* 搜索部分 */
.searchDiv {
  border: Red 1px solid;
  display: flex;
  border: #2254f4 2px solid;
  border-radius: 10px;
  overflow: hidden;
  height: 50px;
}

.searchDiv .el-select {
  width: calc(100% - 104px);
  height: 50px;
}

.searchDiv input {
  border: transparent 1px solid !important;
  height: 50px !important;
  font-size: 1.2rem;
}

.searchDiv .el-input__inner::-webkit-input-placeholder {
  color: #888;
  font-size: 1.2rem;
}

.searchDiv .searchSlot {
  width: 104px;
  cursor: pointer;
  display: block;
  font-size: 1.4rem;
  background: #2254f4;
  color: #fff;
  text-align: center;
  line-height: 50px;
}

.Yandex {
  border: rgb(255, 204, 0) 2px solid !important;
}

.Yandex .searchSlot {
  background: rgb(255, 204, 0);
}

.bd {
  border: rgb(78, 110, 242) 2px solid !important;
}

.bd .searchSlot {
  background: rgb(78, 110, 242);
}

.L360 {
  border: rgb(15, 178, 100) 2px solid !important;
}

.L360 .searchSlot {
  background: rgb(15, 178, 100);
}

.ss {
  border: rgb(255, 90, 52) 2px solid !important;
}

.ss .searchSlot {
  background: rgb(255, 90, 52);
}

.tt {
  border: rgb(240, 65, 66) 2px solid !important;
}

.tt .searchSlot {
  background: rgb(240, 65, 66);
}

/* 搜索上面的切换 */
.tapTabs {
  margin-top: 5px;
}

.tapTabs .el-tabs__nav-wrap::after {
  display: none;
}

.tapTabs .el-tabs__active-bar {
  background: #2254f4;
  height: 3px;
  border-radius: 3px;
}

.tapTabs .el-tabs__content {
  display: none;
}

.tapTabs .el-tabs__header {
  margin: 0;
}

.tapTabs .el-tabs__item {
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  word-break: keep-all;
  cursor: pointer;
  color: #666;
}

.tapTabs .el-tabs__item:hover {
  color: #000;
  cursor: pointer;
}

.tapTabs .is-active {
  color: #000;
}

@media (min-width: 1200px) {
  .el-carousel__item {
    border-radius: 15px;
    overflow: hidden;
  }

  .el-carousel__item img {
    height: 100% !important;
  }

  .carouselDiv .el-carousel__container .is-active {
    width: 950px;
    margin-left: -200px;
    position: relative;
  }

  .carouselDiv .el-carousel__container .is-active img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: auto !important;
    border-radius: 15px;
  }

  .carouselImage {
    display: block !important;
  }

  .carouselImageWap {
    display: none !important;
  }
}

@media (max-width: 1200px) {
  .carouselImage {
    display: none !important;
  }

  .carouselImageWap {
    display: block !important;
  }
}
</style>
