<template>
  <div class="sidebar-menu-box">
    <div class="sidebar-menu flex-fill">
      <div class="sidebar-scroll">
        <div class="sidebar-menu-inner">
          <ul class="slide-bar-ul">
            <li :class="['sidebar-item', active == item.id ? 'active' : '']" v-for="item in silidebarList" :key="item.id" @click="menuHandle(item)">
              <el-popover placement="right" trigger="hover" popper-class="slidePop" :disabled="isActive">
                <span @click="menuHandle(item)">{{ item.name }}</span>
                <div class="slide-bar" slot="reference">
                  <i :class="item.icon"></i><span>{{ item.name }}</span>
                </div>
              </el-popover>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="py-2">
      <div class="flex-bottom">
        <ul>
          <li class="sidebar-item">
            <a @click="goAbout">
              <i class="iconfont icon-tishi" title="关于我们" />
              <span>关于我们</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    silidebarList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      active: 1
    }
  },
  methods: {
    menuHandle(item) {
      this.active = item.id
      this.$emit('scrollToAnchor', item.ref)
    },
    goAbout() {
      this.$router.push('/about')
    }
  }
}
</script>

<style scoped>
.sidebar-menu-box {
  height: calc(100vh - 80px);
  display: flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  flex-direction: column;
  transition: background-color 0.3s;
}
.slide-bar-ul {
  padding: 0;
  margin: 0;
  :hover {
    color: #262b33;
    background-color: #f4f5f6;
  }
  .sidebar-item {
    position: relative;
    display: block;
    cursor: pointer;
    i {
      padding: 12px 10px;
      font-size: 20px;
    }
  }
  .sidebar-item.active {
    color: #262b33;
    background-color: #f4f5f6;
  }
  .slide-bar {
    display: flex;
    align-items: center;
    margin: 1.5px 8px;
    span {
      white-space: nowrap;
    }
  }
}
</style>
<style>
.slidePop.el-popper {
  background: #dadbdd;
  border: 3px solid rgba(60, 71, 76, 0);
  min-width: 100px;
  cursor: pointer;
  .popper__arrow {
    left: -8px;
    &::after {
      border-right-color: #dadbdd !important;
    }
  }
}
</style>
